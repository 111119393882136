<template>
    <modal class="calculationModel" :isShow="isShow">
        <div class="header">成本重新核算</div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">营业日期：</span>
                    <div class="from-input">
                        <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="businessHours"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="defaultTime"
                            ref="datePicker"
                        > </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()">出品部门<br/>重新核算</button>
            <button class="btn selected" @click="confirm(1)">成本重新核算</button>
        </div>
        
    </modal>
</template>

<script>
// 成本重新核算
export default {
    name:'calculationModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            userInfo:null,
            businessHours:[new Date(),new Date()],
            defaultTime:[new Date(),new Date()],
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.userInfo= this.$auth.getUserInfo();
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
           
        });
    },
    methods:{
        //点击确认重新核算 按设置重新核算 厨师名称
        confirm(type){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            if(!time){
                this.$message.warning("请选择营业日期范围!");
                return;
            }
            
            let beginDate=new Date(new Date(time[0]).Format("yyyy-MM-dd"));
            beginDate.setDate(31);
            let maxDate=beginDate.getTime();
            let endDate=new Date(new Date(time[1]).Format("yyyy-MM-dd"));


            if(maxDate<endDate.getTime()){
                this.$message.warning("营业日期范围最多可选择31天!");
                return;
            }


            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                BeginDate:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                EndDate:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }

            if(type==1){
                this.$confirm("您确认将["+param.BeginDate+"]到["+param.EndDate+"] 的成本重新核算吗?","提示", {
                    confirmButtonText: "确认",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.recountCost(param);
                        }
                    },
                })
            }else{
                this.$confirm("您确认将["+param.BeginDate+"]到["+param.EndDate+"] 的菜品销售出品部门重新核算吗?","提示", {
                    confirmButtonText: "确认",
                    cancelButtonText:"取消",
                    callback: (name) => {
                        if(name=='confirm'){
                            this.recountChhdepart(param);
                        }
                    },
                })
            }
        },
        /**出品部门重新核算 */
        recountChhdepart(param){
            const loading = this.$loading({
                text: "出品部门重新核算中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            /**超时时间2分钟 */
            this.$httpAES.requestConfig({timeout:30*1000*2}).post("Bestech.CloudPos.RecountChhdepart",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("出品部门重新核算成功");
                    this.hide();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('出品部门重新核算失败：'+e.message);
                console.log('出品部门重新核算失败：',e);
            })
        },
        /**成本重新核算 */
        recountCost(param){
            const loading = this.$loading({
                text: "成本重新核算中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            /**超时时间2分钟 */
            this.$httpAES.requestConfig({timeout:30*1000*2}).post("Bestech.CloudPos.RecountCost",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("成本重新核算成功");
                    this.hide();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('成本重新核算失败：'+e.message);
                console.log('成本重新核算失败：',e);
            })
        },
        hide(){
            this.$emit("close");
        },
        
    }
}
</script>

<style lang="scss">
@import './calculationModel.scss'
</style>